import { createContext, useContext, type ReactNode } from "react";
import { useCustomerInfo, type CustomerInfoData } from "@hooks/useCustomerInfo";

interface CustomerInfoProviderProps {
  children: ReactNode;
}

export const CustomerInfoContext = createContext<CustomerInfoData | undefined>(undefined);

export const CustomerInfoProvider = ({ children }: CustomerInfoProviderProps) => {
  const customerInfo = useCustomerInfo();

  return (
    <CustomerInfoContext.Provider value={customerInfo}>{children}</CustomerInfoContext.Provider>
  );
};

export const useCustomerInfoContext = (): CustomerInfoData => {
  const context = useContext(CustomerInfoContext);
  if (!context) {
    throw new Error("useCustomerInfoContext must be used within a CustomerInfoProvider");
  }
  return context;
};
