import React, { type ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "@components/common/Spinner";

export type ButtonType =
  | "default"
  | "outline"
  | "outline2"
  | "filled"
  | "option"
  | "disabled"
  | "text"
  | "purple"
  | "plain"
  | "active"
  | "purple2"
  | "black";

interface ButtonProps {
  id?: string;
  to?: string;
  title: string | JSX.Element;
  className?: string;
  type?: ButtonType;
  onClick?: (e: any) => void;
  isSubmit?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

interface WrapperProps extends Omit<ButtonProps, "title"> {
  children: ReactNode;
  className: string;
}

const baseStyles =
  "border border-black rounded text-[21px] leading-5 px-4 py-2 inline-block cursor-pointer flex items-center gap-2";
const optionsBaseStyles =
  "w-full h-[70px] text-white font-normal flex justify-center items-center rounded-[4px]";

const darkSyles = `dark:text-[21px] dark:font-normal dark:text-white dark:border-solid dark:border-2 dark:border-white dark:bg-none dark: dark:bg-black`;

const buttonTypes = {
  default:
    "flex justify-center items-center w-[280px] h-[65px] bg-white text-black font-bold shadow-really",
  outline:
    "bg-white text-black font-normal border w-[280px] h-[65px] flex justify-center items-center border-black",
  outline2:
    "bg-white text-black font-normal border w-[280px] h-[56px] flex justify-center items-center border-black px-[8px] !text-[18px]",
  filled: `bg-[#00000080] bg-right-bottom transition-bg-pos duration-200 ease-in-out hover:bg-left-bottom bg-buttonBgGradient bg-200p-100p ${optionsBaseStyles}`,
  option: `bg-black ${optionsBaseStyles} text-[17px] font-normal`,
  disabled: `bg-[#acaaa8] ${optionsBaseStyles} border-0 !cursor-auto`,
  text: `!text-black ${optionsBaseStyles} text-[24px] font-normal border-0 underline underline-offset-[5px] mx-auto`,
  purple: `${optionsBaseStyles} bg-[#7151f0] text-[24px] font-normal border-0 underline rounded-[35px]`,
  purple2: `${optionsBaseStyles} bg-[#7151f0] !text-[16px] font-normal border-0 rounded-[35px] !h-[59px]`,
  black: `${optionsBaseStyles} bg-[#000] !text-[14px] font-normal border-0 rounded-[35px] !h-[36px] py-[8px] px-[12px]`,
  plain: "",
  active: `bg-[#acaaa8] bg-buttonBgGradient bg-200p-100p bg-no-repeat animate-slide-bg ${optionsBaseStyles} ${darkSyles}`,
};

const Wrapper: React.FC<WrapperProps> = ({ children, ...props }) => {
  const { isSubmit, loading, onClick, to, ...restProps } = props;

  if (onClick || isSubmit) {
    return (
      <button {...restProps} onClick={onClick} type={isSubmit ? "submit" : "button"}>
        {children}
        {loading && <Spinner color="#ffffff" />}
      </button>
    );
  } else {
    return (
      <Link to={to || ""} {...restProps}>
        {children}
      </Link>
    );
  }
};

export const Button: React.FC<ButtonProps> = ({
  id,
  to = "",
  title,
  className = "",
  type: initialType = "default",
  onClick,
  isSubmit,
  disabled,
  loading,
  ...restProps
}) => {
  const buttonStyles = useMemo(() => {
    const type = disabled || loading ? "disabled" : initialType;
    return `${baseStyles} ${buttonTypes[type]} ${className}`;
  }, [initialType, className, disabled, loading]);
  const wrapperProps = useMemo(
    () => ({ to, onClick, isSubmit, disabled, loading, id, ...restProps }),
    [to, onClick, isSubmit, disabled, loading, id, restProps],
  );

  return (
    <Wrapper {...wrapperProps} className={buttonStyles}>
      {title}
    </Wrapper>
  );
};
